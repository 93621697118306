import "./CV.css";

import React from "react";

function Cv() {
  return (
    <>
      <div className="cv-body">
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        />
        <div className="content">
          <div className="content-items">
            <div className="title">Topaz Turkenitz</div>
            <h3>Education</h3>
            Bachelor of Computer Science 2017 Royal Melbourne Institute of
            Technology, Melbourne Australia
            <h3>Technical Skills</h3>
            <div>
              <ul className="skill-set">
                <li>Golang</li>
                <li>React</li>
                <li>PHP</li>
                <li>Javascript/Typescript</li>
                <li>Graphql, Apollo</li>
                <li>RPC Twirp</li>
                <li>Git</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>AWS</li>
                <li>Docker</li>
                <li>Node</li>
              </ul>
            </div>
            <h3>Employment history</h3>
            <ul>
              <a href=" ">
                Bootstrap startup - graphics video streaming for broadcasters
              </a>
              <li>
                Used mediastream API to build a react microservice that displays
                a live media stream on HTML5 canvas. I also setup automated
                integration testing and integrated github actions.
              </li>
              <b>
                <br />
                <a href="99designs.com">99designs</a>
              </b>
              <p>Platform Engineering team</p>
              <p>
                A global creative platform that helps you find and hire talented
                designers to grow your business. It practiced continuous
                delivery(daily deployments), agile environment and a
                Microservices architecture.
              </p>
              <li>
                Worked on a re-entrant user erasure system to comply with GDPR.
                It allowed for user PII to be safely removed across distributed
                systems.
                <span id="more">
                  - I created a dashboard for support staff that automates the
                  process to erase users.
                </span>
                <span id="more"></span>
              </li>
              <li>
                {" "}
                Worked on the internal admin dashboard.
                <span id="more">
                  - Enhanced interal admin dashboards by creating audibility
                  logs and tightening permissions.
                </span>
              </li>
              Implemented a Fraud system. This involved piping events from our
              varying microservices using Twirp RPC calls to a fraud provider
              for eventual fraud analysis.
              <li>
                Migrating a legacy Authentication system to use JWT with zero
                downtime.
                <span id="more">
                  - Created a sessions Go microservice dedicated to hanuling the
                  sites authentication - Ported the authentication microservice
                  to PHP
                </span>
              </li>
              <li>Updated the sites accounts settings page to modern react</li>
              <li>
                Maintained internal systems that serve as the backbone of the
                site (Payments, Payouts, Accounts, Frontend router etc) and took
                part in the on-call operations roster.
              </li>
              <li>
                Previously worked on the team responsible for the core product.
                The work included developing new features, email & site
                notifications and bug fixes on a legacy Symfony codebase.
                <span id="more">
                  <a href="https://99designs.community/t/requesting-money-for-progress-payments/243115">
                    See.
                  </a>
                  <br />
                  <a
                    href="https://99designs.community/t/progress-payments-in-projects/237543"
                    className="href"
                  >
                    more.
                  </a>
                  <br />
                  <a
                    href="https://99designs.community/t/improvements-to-quoting-and-displaying-invoices-in-projects/234945"
                    className="href"
                  >
                    here.
                  </a>
                  <br />
                </span>
              </li>
              <li>
                Worked closely with product owners, designers and marketers as
                part of our continuous development process.
              </li>
              <h3>Personal Projects</h3>
              <a href="https://chrome.google.com/webstore/detail/trello-custom-progress-ba/afbaaokfpieehjeffeceajdopnlempob?hl=en&authuser=2">
                Chrome extension: “Trello Custom progress bar”
              </a>
              <li>IPhone App: “mnemonic major systems”</li>
              <h3>Awards & Volunteer Work</h3>
              <a href="https://twitter.com/jsconfau?lang=en">
                CSSConf and JSConf AU 2018 Event Volunteer.
              </a>
              <li>
                <a href="https://australia.googleblog.com/2010/06/for-love-of-soccer-vote-for-your.html">
                  Two-time design finalist for Google’s “Dooule4Google” logo
                  design competition 08/09.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Cv;
